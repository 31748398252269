import { Anchor, redirectAppleAppStoreUrl, redirectGooglePlayUrl } from '@vivino/js-web-common';
import React from 'react';

import DownloadAppleStore from '@components/icons/DownloadAppleStore/DownloadAppleStore';
import DownloadGooglePlay from '@components/icons/DownloadGooglePlay/DownloadGooglePlay';

import styles from './downloadAppButtons.scss';

const DownloadAppButtons = () => (
  <div className={styles.appCTALinks}>
    <Anchor
      title="Apple AppStore"
      href={redirectAppleAppStoreUrl()}
      className={styles.downloadAppCTA}
      target="_blank"
      rel="noopener noreferrer"
    >
      <DownloadAppleStore />
    </Anchor>
    <Anchor
      title="Google Play"
      href={redirectGooglePlayUrl()}
      className={styles.downloadAppCTA}
      target="_blank"
      rel="noopener noreferrer"
    >
      <DownloadGooglePlay />
    </Anchor>
  </div>
);

export default DownloadAppButtons;
