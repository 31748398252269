import React from 'react';

interface QuestionBubbleProps {
  className?: string;
}

const QuestionBubble = ({ className }: QuestionBubbleProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    className={className}
  >
    <g id="ea36e455-db1d-4617-b98b-3e7ef92bf30a" data-name="48">
      <g id="af1fa49f-154e-478f-87ec-cbfd59a88d0c" data-name="support">
        <g id="a90c6384-ca6a-4f31-ae72-f258c293a508" data-name="support">
          <g id="ae31281e-184b-476d-8f6a-9d4e691024e0" data-name="comment-fill">
            <g id="f64057c8-9613-4b62-804c-93d59b010c40" data-name="comment-fill">
              <path
                d="M39.3175,16.8835c-3.7183,0-6.7325,2.4383-6.7325,5.4475a5.0629,5.0629,0,0,0,2.2928,4.0879,9.1223,9.1223,0,0,1-1.1209,2.0777c1.1891.5041,2.5352-.21,3.5233-.9734a8.1947,8.1947,0,0,0,2.0373.2553c3.7183,0,6.7325-2.4391,6.7325-5.4475S43.0358,16.8835,39.3175,16.8835Z"
                fill="none"
                stroke="#1e1e1e"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <g id="babf6d05-10b3-405a-bd48-a926510667f1" data-name="comment-fill">
              <path
                d="M39.3175,16.8835c-3.7183,0-6.7325,2.4383-6.7325,5.4475a5.0629,5.0629,0,0,0,2.2928,4.0879,9.1223,9.1223,0,0,1-1.1209,2.0777c1.1891.5041,2.5352-.21,3.5233-.9734a8.1947,8.1947,0,0,0,2.0373.2553c3.7183,0,6.7325-2.4391,6.7325-5.4475S43.0358,16.8835,39.3175,16.8835Z"
                fill="none"
                stroke="#1e1e1e"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
          <path
            d="M11.9673,14.4624S6.81,23.6832,12.652,25.1706"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
            fillRule="evenodd"
          />
          <path
            d="M31.5466,10.8513c1.512,2.247,2.1311,5.1116,1.6171,8.9652"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
            fillRule="evenodd"
          />
          <path
            d="M31.451,25.509a12.7719,12.7719,0,0,1-3.3126,3.2732"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
            fillRule="evenodd"
          />
          <path
            d="M18.7716,28.92A8.163,8.163,0,0,1,16.03,26.5709"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
            fillRule="evenodd"
          />
          <path
            d="M13.6635,21.6353a18.73,18.73,0,0,1-.5175-3.4446"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
            fillRule="evenodd"
          />
          <path
            d="M32.431,34.3788a16.48,16.48,0,0,1-8.0254,2.2726,22.3042,22.3042,0,0,1-9.8-2.0465"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
            fillRule="evenodd"
          />
          <path
            d="M31.6014,7.3842s.7321-.1859,2.4521-2.6026-6.2457,2.15-11.0755,0C15.9549,1.6558,8.463,7.1209,13.146,18.1907c0,0,2.528-6.4561,9.5882-4.5971s6.54-2.9182,7.744-2.8633C30.4782,10.73,35.9338,10.9162,31.6014,7.3842Z"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="b641e218-ac0f-4a9b-9846-3bf5104e4ab8"
            data-name="profile"
            d="M28.1548,28.7822c.0555,3.6642,1.31,4.45,6.4745,6.238a30.2419,30.2419,0,0,1,4.1187,1.6351m-29.7256.17c.98-.4864,2.1262-.9837,3.4372-1.4811,5.1081-1.938,6.3675-2.7609,6.312-6.425"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
            fillRule="evenodd"
          />
          <rect
            x="12.6105"
            y="23.2201"
            width="4.4048"
            height="3.165"
            rx="1.4659"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-1.9207 1.2531) rotate(-4.5496)"
          />
          <path
            d="M27.6493,21.912a4.9249,4.9249,0,0,1-4.0624,2.657,4.9238,4.9238,0,0,1-4.06-2.657"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M4,24A19.9773,19.9773,0,0,1,12.5662,7.5884"
          fill="none"
          stroke="#1e1e1e"
          strokeLinecap="round"
          strokeLinejoin="round"
          fillRule="evenodd"
        />
        <path
          d="M33.1637,6.2182a20.0585,20.0585,0,0,1,9.8755,11.64"
          fill="none"
          stroke="#1e1e1e"
          strokeLinecap="round"
          strokeLinejoin="round"
          fillRule="evenodd"
        />
        <path
          d="M43.8521,26.4443A20.0011,20.0011,0,0,1,4,24"
          fill="none"
          stroke="#1e1e1e"
          strokeLinecap="round"
          strokeLinejoin="round"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

export default QuestionBubble;
