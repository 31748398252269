import React from 'react';

interface OutlineIconProps {
  className?: string;
}

export const FacebookOutlineIcon = ({ className }: OutlineIconProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill="#1e1e1e"
      d="M23 12c0-6.076-4.924-11-11-11S1 5.924 1 12c0 5.157 3.55 9.486 8.342 10.674v-7.312h-2.27V12h2.27v-1.448c0-3.744 1.694-5.478 5.368-5.478.696 0 1.901.137 2.39.273v3.045c-.26-.026-.709-.04-1.272-.04-1.804 0-2.5.682-2.5 2.46V12h3.596l-.616 3.362h-2.975v7.559C18.78 22.26 23 17.623 23 12z"
    />
  </svg>
);

export const InstagramOutlineIcon = ({ className }: OutlineIconProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <g fill="#1e1e1e">
      <path d="M12 3.8c2.672 0 2.988.013 4.04.06.976.042 1.503.206 1.855.343.464.18.8.399 1.148.746.351.352.566.684.746 1.149.137.351.3.882.344 1.855.047 1.055.058 1.371.058 4.04 0 2.671-.011 2.988-.058 4.038-.043.977-.207 1.504-.344 1.856-.18.465-.398.8-.746 1.148a3.077 3.077 0 0 1-1.149.746c-.351.137-.882.301-1.855.344-1.055.047-1.371.059-4.039.059-2.672 0-2.988-.012-4.04-.059-.976-.043-1.503-.207-1.855-.344-.464-.18-.8-.398-1.148-.746a3.076 3.076 0 0 1-.746-1.148c-.137-.352-.3-.883-.344-1.856-.047-1.054-.058-1.37-.058-4.039 0-2.672.011-2.988.058-4.039.043-.976.207-1.504.344-1.855.18-.465.398-.801.746-1.149a3.076 3.076 0 0 1 1.148-.746c.352-.137.883-.3 1.856-.344 1.05-.046 1.367-.058 4.039-.058zM12 2c-2.715 0-3.055.012-4.121.059-1.063.046-1.793.218-2.426.464A4.88 4.88 0 0 0 3.68 3.68a4.9 4.9 0 0 0-1.157 1.77c-.246.636-.418 1.363-.464 2.425C2.012 8.945 2 9.285 2 12s.012 3.055.059 4.121c.046 1.063.218 1.793.464 2.426.258.66.598 1.219 1.157 1.773a4.888 4.888 0 0 0 1.77 1.153c.636.246 1.363.418 2.425.464 1.066.047 1.406.06 4.121.06 2.715 0 3.055-.013 4.121-.06 1.063-.046 1.793-.218 2.426-.464.656-.254 1.215-.598 1.77-1.153s.898-1.113 1.152-1.77c.246-.636.418-1.363.465-2.425.047-1.066.058-1.406.058-4.121 0-2.715-.011-3.055-.058-4.121-.047-1.063-.22-1.793-.465-2.426A4.683 4.683 0 0 0 20.32 3.68a4.889 4.889 0 0 0-1.77-1.153c-.636-.246-1.363-.418-2.425-.465C15.055 2.012 14.715 2 12 2z" />
      <path d="M12 6.863A5.138 5.138 0 0 0 6.863 12a5.138 5.138 0 0 0 10.273 0c0-2.836-2.3-5.137-5.136-5.137zm0 8.469a3.333 3.333 0 1 1 .001-6.665A3.333 3.333 0 0 1 12 15.332zM18.54 6.66a1.2 1.2 0 1 1-2.4 0 1.2 1.2 0 0 1 2.4 0z" />
    </g>
  </svg>
);

export const TwitterOutlineIcon = ({ className }: OutlineIconProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill="#1e1e1e"
      stroke="#1e1e1e"
      strokeLinecap="round"
      strokeLinejoin="bevel"
      strokeWidth=".36"
      d="M13.675 10.622 20.233 3H18.68l-5.694 6.618L8.438 3H3.193l6.877 10.007L3.193 21h1.554l6.012-6.989L15.563 21h5.244zm-2.128 2.474-.696-.997-5.544-7.93h2.387l4.473 6.4.697.997 5.815 8.318h-2.386z"
    />
  </svg>
);

export const SmileyOutlineIcon = ({ className }: OutlineIconProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill="#231f20"
      fillRule="evenodd"
      d="M14.713 8.32c.483 0 .839.2 1.067.612.105.191.164.4.164.627 0 .209-.053.406-.15.587-.24.427-.598.641-1.081.641s-.828-.194-1.061-.586c-.123-.2-.178-.414-.178-.642s.05-.439.164-.627c.233-.412.597-.612 1.075-.612zm-5.431 0c.494 0 .852.2 1.08.612.109.191.164.4.164.627s-.055.425-.164.614c-.228.409-.586.614-1.08.614-.495 0-.84-.205-1.062-.614a1.207 1.207 0 0 1-.166-.614c0-.239.055-.452.166-.641.242-.4.598-.598 1.062-.598zm2.714-5.061c-2.409 0-4.47.855-6.179 2.57S3.25 9.6 3.25 12.003s.856 4.468 2.567 6.17c1.709 1.704 3.773 2.562 6.179 2.562s4.462-.858 6.176-2.561c1.714-1.706 2.57-3.765 2.57-6.17 0-2.407-.856-4.463-2.57-6.177s-3.773-2.57-6.176-2.57zm0-1.259c2.756 0 5.114.978 7.07 2.934C21.022 6.89 22 9.245 22 12.004c0 2.759-.978 5.11-2.934 7.065-1.956 1.956-4.311 2.934-7.07 2.934-2.759 0-5.115-.978-7.065-2.934C2.978 17.113 2 14.757 2 12.004s.978-5.114 2.934-7.07C6.884 2.978 9.243 2 11.999 2zm5.648 9.198h1.392c0 1.965-.695 3.637-2.081 5.026-1.39 1.39-3.059 2.081-5.015 2.081-1.956 0-3.622-.695-5.012-2.08-1.389-1.39-2.08-3.06-2.08-5.027h1.391c0 1.584.553 2.926 1.673 4.029 1.114 1.108 2.453 1.658 4.028 1.658s2.92-.55 4.034-1.658c1.114-1.103 1.673-2.445 1.673-4.028z"
      clipRule="evenodd"
    />
  </svg>
);
