import React from 'react';
import Page from 'vivino-js/utilities/Page/Page';

import Footer from '@components/Footer/Footer.presentational';

interface AppProps {
  countryCode: string;
  stateCode?: string;
  isVCCountry: boolean;
  translations: Record<string, string>;
}

export default ({ countryCode, stateCode, isVCCountry, translations }: AppProps) => {
  return (
    <Page translations={translations}>
      <Footer countryCode={countryCode} stateCode={stateCode} isVCCountry={isVCCountry} />
    </Page>
  );
};
