import React from 'react';

interface DeliveryIconProps {
  className?: string;
}

const Delivery = ({ className }: DeliveryIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    className={className}
  >
    <g id="e8b44cb4-07ba-4f28-baaa-692f42e3ad55" data-name="48">
      <g id="b364ddee-4f3c-4437-b25e-77d7481dd2d4" data-name="delivery">
        <g id="b8d1d34c-f43f-4556-8a57-ce34853bc6bf" data-name="delivery">
          <line
            x1="29.589"
            y1="24.8285"
            x2="40.9949"
            y2="24.8285"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <polyline
            points="29.589 14.134 29.589 37.44 22.564 37.44"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.1255,37.44H4.6584a2.2626,2.2626,0,0,1-2.2626-2.2625V22.7587a2.2671,2.2671,0,0,1,2.3248-2.2663h0"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M41.8,37.432l3.0237.0043a2.306,2.306,0,0,0,2.3119-2.3956,26.1079,26.1079,0,0,0-4.9937-14.8856l-3.56-8.009a2.3955,2.3955,0,0,0-2.189-1.4224H30.9247a1.3357,1.3357,0,0,0-1.3357,1.3357V37.44h3.1155"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            cx="37.252"
            cy="38.6127"
            r="4.5475"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            cx="15.673"
            cy="38.5136"
            r="4.5475"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="bf7a5b6a-2089-4b0b-a08c-4304225b95d9" data-name="bottles">
            <path
              d="M14.3551,9.6536V2.5442a.7378.7378,0,0,0-.7478-.723h-1.89a.7379.7379,0,0,0-.7479.723V9.6536"
              fill="none"
              stroke="#1e1e1e"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.97,9.6536a4.63,4.63,0,0,0-2.9186,4.2883V28.05a1.4324,1.4324,0,0,0,1.4316,1.4333h6.3593A1.4325,1.4325,0,0,0,17.2738,28.05V13.9419a4.63,4.63,0,0,0-2.9187-4.2883"
              fill="none"
              stroke="#1e1e1e"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <g id="b6da87ca-5a04-4741-b49c-314aa2b57fff" data-name="winelist">
              <path
                d="M23.0106,11.4376v-6.47a.6715.6715,0,0,0-.68-.6579H20.61a.6715.6715,0,0,0-.68.6579v6.47"
                fill="none"
                stroke="#1e1e1e"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.93,11.4376A4.2137,4.2137,0,0,0,17.2738,15.34v12.839a1.3036,1.3036,0,0,0,1.3029,1.3043h5.7871a1.3036,1.3036,0,0,0,1.3029-1.3043V15.34a4.2137,4.2137,0,0,0-2.6561-3.9025"
                fill="none"
                stroke="#1e1e1e"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <line
              x1="10.9698"
              y1="4.3099"
              x2="14.3551"
              y2="4.3099"
              fill="none"
              stroke="#1e1e1e"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1="19.9299"
              y1="6.9951"
              x2="23.0106"
              y2="6.9951"
              fill="none"
              stroke="#1e1e1e"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect
              x="10.3573"
              y="14.9214"
              width="4.7633"
              height="6.1471"
              fill="none"
              stroke="#1e1e1e"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect
              x="19.4004"
              y="15.778"
              width="4.1398"
              height="6.6356"
              fill="none"
              stroke="#1e1e1e"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <circle
            cx="15.673"
            cy="38.5136"
            r="0.5465"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            cx="37.252"
            cy="38.6127"
            r="0.5465"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <line
          x1="4.7206"
          y1="20.4924"
          x2="5.8575"
          y2="20.4924"
          fill="none"
          stroke="#1e1e1e"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="37.5429"
          y1="22.4567"
          x2="41.5161"
          y2="22.3287"
          fill="none"
          stroke="#1e1e1e"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

export default Delivery;
