import { AnyWinery } from '@webtypes/rubyLibApi';

const WineryStatuses = {
  Unclaimed: 0,
  Claimed: 1,
  Pro: 2,
  Sponsor: 3,
};

export const isPartnerWinery = (winery: AnyWinery) => winery?.status === WineryStatuses.Sponsor;

export const numberOfWinesText = (count: number, translations: { [key: string]: string }) => {
  if (count === 0) {
    return translations.number_of_wines_zero;
  } else if (count === 1) {
    return translations.number_of_wines_one;
  } else {
    return translations.number_of_wines_other;
  }
};

export const totalRatingsText = (rating: number, translations: { [key: string]: string }) => {
  if (rating === 0) {
    return translations.total_ratings_zero;
  } else if (rating === 1) {
    return translations.total_rating_one;
  } else {
    return translations.total_ratings_other;
  }
};
