import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { node } from 'vivino-js/commonPropTypes';

import styles from './card.scss';

const Card = ({ children, className, onClick, id, ariaPressed, ariaLabel }) => {
  if (onClick) {
    return (
      <button
        className={cx(styles.card, className)}
        id={id}
        aria-label={ariaLabel}
        aria-pressed={ariaPressed}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }

  return (
    <div className={cx(styles.card, className)} id={id}>
      {children}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  ariaLabel: PropTypes.string,
  ariaPressed: PropTypes.bool,
};

export default Card;
