import {
  FacebookOutlineIcon,
  InstagramOutlineIcon,
  SmileyOutlineIcon,
  TwitterOutlineIcon,
} from '@icons/SocialMediaOutlineIcons/SocialMediaOutlineIcons';
import {
  Anchor,
  facebookUrl,
  instagramUrl,
  smileyReportUrl,
  twitterUrl,
} from '@vivino/js-web-common';
import React from 'react';

import styles from './socialMediaLinks.scss';

const SOCIAL_MEDIA_LINK = [
  { title: 'Instagram', link: instagramUrl(), icon: <InstagramOutlineIcon /> },
  { title: 'Facebook', link: facebookUrl(), icon: <FacebookOutlineIcon /> },
  { title: 'Twitter', link: twitterUrl(), icon: <TwitterOutlineIcon /> },
];

const COUNTRY_BASED_SOCIAL_MEDIA_LINK = {
  dk: [{ title: 'Smiley report', link: smileyReportUrl(), icon: <SmileyOutlineIcon /> }],
};

interface SocialMediaLinksProps {
  countryCode: string;
}

const SocialMediaLinks = ({ countryCode }: SocialMediaLinksProps) => {
  const COUNTRY_BASED_LINK = COUNTRY_BASED_SOCIAL_MEDIA_LINK[countryCode.toLowerCase()] || [];

  return (
    <div className={styles.socialMediaLinks}>
      {COUNTRY_BASED_LINK.concat(SOCIAL_MEDIA_LINK).map((element) => (
        <Anchor
          title={element.title}
          key={element.link}
          href={element.link}
          className={styles.socialMediaLink}
          target="_blank"
          rel="noopener noreferrer"
          dataTestId={element.title}
        >
          {element.icon}
        </Anchor>
      ))}
    </div>
  );
};

export default SocialMediaLinks;
