import React from 'react';

interface RibbonAwardProps {
  className?: string;
}

const RibbonAward = ({ className }: RibbonAwardProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    className={className}
  >
    <g id="e7ff7d8e-a6f6-40e6-8907-62eac873e4c0" data-name="48">
      <g id="aa08e429-6029-46c6-8027-296324821343" data-name="marketplace">
        <g id="fd48e2f7-2efc-4687-8bd1-010905994d34" data-name="bottle">
          <path
            d="M15.4668,14.2426V4.2193A1.04,1.04,0,0,0,14.4124,3.2H11.7483a1.04,1.04,0,0,0-1.0544,1.0193V14.2426"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.6939,14.2426A6.528,6.528,0,0,0,6.579,20.2885V40.1793A2.02,2.02,0,0,0,8.5975,42.2h8.9657a2.02,2.02,0,0,0,2.0185-2.0207V37.4355"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.2292,16.3308a6.57,6.57,0,0,0-2.7624-2.0882"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect
            x="7.6535"
            y="20.7829"
            width="8.093"
            height="8.093"
            rx="1.4525"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(20.9838 -1.0008) rotate(45)"
          />
          <line
            x1="10.6939"
            y1="6.2694"
            x2="15.4668"
            y2="6.2694"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="9.5837"
            y1="36.4723"
            x2="13.8162"
            y2="36.4723"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g id="bf439ae5-4780-4c77-bff6-8324c2d027a5" data-name="rosette">
          <g id="f59456fc-b15b-4067-bfbf-0b880bbb247e" data-name="rosette">
            <path
              d="M42.821,19.241c-.2372-1.3016.467-2.9773-.1727-4.1658-.6168-1.1463-2.3825-1.4741-3.3718-2.4208s-1.4032-2.7058-2.5712-3.2689c-1.2105-.5832-2.838.2091-4.14.0362-1.3158-.175-2.6836-1.361-3.9884-1.12-1.2929.2387-2.1518,1.8315-3.3313,2.475-1.1391.6209-2.9378.4616-3.8786,1.4575s-.6921,2.7968-1.2509,3.9728c-.5793,1.2192-2.1158,2.1623-2.2877,3.474-.1737,1.3248,1.0717,2.64,1.3109,3.9547.2372,1.3011-.4669,2.9768.1728,4.1653.6167,1.1462,2.383,1.4741,3.3718,2.4213s1.4032,2.7053,2.5712,3.2688c1.21.5832,2.8379-.2091,4.1407-.0356,1.3159.1749,2.6831,1.36,3.9885,1.12,1.2928-.2393,2.1513-1.8311,3.3313-2.475,1.1391-.62,2.9383-.4616,3.8791-1.4571s.6921-2.7967,1.25-3.9727c.5792-1.2187,2.1163-2.1623,2.2881-3.474C44.3057,21.87,43.06,20.5547,42.821,19.241Z"
              fill="none"
              stroke="#1e1e1e"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle
              cx="30.9804"
              cy="21.4383"
              r="5.0951"
              fill="none"
              stroke="#1e1e1e"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29.5876,36.8909,27.91,40.4032a1.1125,1.1125,0,0,1-1.8658.1648L23.7414,37.59a1.1123,1.1123,0,0,0-.9619-.4289l-3.9135.289a1.1126,1.1126,0,0,1-1.0677-1.6253L21.2456,29.29"
              fill="none"
              stroke="#1e1e1e"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M40.5944,30.6429l3.21,4.1433a1.1125,1.1125,0,0,1-.8094,1.7916l-3.8029.24a1.1128,1.1128,0,0,0-.9067.5776l-1.78,3.2632a1.1126,1.1126,0,0,1-1.8478.1594L30.196,35.1451"
              fill="none"
              stroke="#1e1e1e"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default RibbonAward;
