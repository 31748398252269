import { useBreakpoint } from '@vivino/js-react-common-ui';
import { Anchor, RatingType } from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { pointerPositionInElement } from 'vivino-js/helpers/eventHelpers';
import t from 'vivino-js/translationString';
import ToolTip, { ToolTipPosition } from 'vivino-ui/atoms/ToolTip';

import Rating from '@components/Rating/Rating';

import { calculateRatingWithPrecision } from './calculateRatingWithPrecision';
import styles from './reviewRatingStars.scss';

const TRANSLATIONS = {
  dragMe: 'components.shared.review_rating_stars.drag_me',
  addRating: 'components.shared.review_rating_stars.add_rating',
};

const UNSET_RATING = 0;
const LEFT_CLICK = 0;

const ReviewRatingStars = ({ initialRating, margin, onRatingClick, size = 24 }) => {
  /**
   * Internal state while the user is hovering. To have a streamlined logic and minimise redraws,
   * we're using the current rating as the prop for the stars array
   */
  const [currentRating, setCurrentRating] = useState(initialRating || UNSET_RATING);

  const [hovering, setHovering] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState(initialRating ? null : '50%');
  const { isDesktop } = useBreakpoint();

  /**
   * If the rating state in the parent has been set to null, update the internal state if the user
   * is not hovering - hovering is a temporary representation of the user's selection intent.
   */

  useEffect(() => {
    if (!hovering) {
      setCurrentRating(initialRating || UNSET_RATING);
    }
  }, [hovering, initialRating]);

  const handleMove = (e) => {
    setHovering(true);

    const pointerPosition = pointerPositionInElement(e);
    const elementWidth = e.currentTarget.offsetWidth;
    const ratingWithPrecision = calculateRatingWithPrecision({
      pointerPosition,
      elementWidth,
      precision: 0.5,
    });
    setCurrentRating(ratingWithPrecision);
    setTooltipPosition(`${pointerPosition}px`);
  };

  const handleTouchStart = (e) => {
    setHovering(true);

    const pointerPosition = pointerPositionInElement(e);
    const elementWidth = e.currentTarget.offsetWidth;
    const ratingWithPrecision = calculateRatingWithPrecision({
      pointerPosition,
      elementWidth,
      precision: 1.0,
    });
    setCurrentRating(ratingWithPrecision);
    setTooltipPosition(`${pointerPosition}px`);
  };

  const handleMouseLeave = () => {
    setTooltipPosition(null);
    setCurrentRating(initialRating);
    setHovering(false);
  };

  const handleMouseUp = (e) => {
    if (e.button === LEFT_CLICK) {
      onRatingClick({ rating: currentRating });
      setHovering(false);
    }
  };

  const handleTouchEnd = (e) => {
    // without these, handleBackdropClick.handleBackdropClick gets called
    // immediately after showing modal
    e.stopPropagation();
    e.preventDefault();

    setTooltipPosition(null);
    onRatingClick({ rating: currentRating });
    setHovering(false);
  };

  const toolTipText = t(isDesktop ? TRANSLATIONS.addRating : TRANSLATIONS.dragMe);

  return (
    <div className={styles.ratingStars}>
      <div className={cx(styles.tooltipContainer)} style={{ left: tooltipPosition }}>
        {tooltipPosition && (
          <ToolTip position={ToolTipPosition.Center} show className={cx(styles.tooltip)}>
            {currentRating > 0 ? currentRating : toolTipText}
          </ToolTip>
        )}
      </div>
      <Rating
        theme={RatingType.User}
        margin={margin}
        size={size}
        rating={parseFloat(currentRating || UNSET_RATING)}
        maxRating={5}
      />
      {/* Due to img elements preventing the touchMove event from firing we have an anchor overlay that handles the events*/}
      <Anchor
        dataTestId="ratingTouchHandler"
        className={styles.anchor}
        onMouseMove={handleMove}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleMove}
        onTouchEnd={handleTouchEnd}
      />
    </div>
  );
};

ReviewRatingStars.propTypes = {
  onRatingClick: PropTypes.func.isRequired,
  initialRating: PropTypes.number,
  size: PropTypes.number,
  margin: PropTypes.string,
};

export default ReviewRatingStars;
