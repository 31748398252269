import { BasicCountry, CountryState } from '@vivino/js-web-common';
import { isMatch } from './text';

export const AT = 'AT';
export const AU = 'AU';
export const BE = 'BE';
export const BR = 'BR';
export const CA = 'CA';
export const CZ = 'CZ';
export const CH = 'CH';
export const DE = 'DE';
export const DK = 'DK';
export const ES = 'ES';
export const FI = 'FI';
export const FR = 'FR';
export const GB = 'GB';
export const HK = 'HK';
export const IE = 'IE';
export const IT = 'IT';
export const JP = 'JP';
export const MC = 'MC';
export const NL = 'NL';
export const SE = 'SE';
export const SG = 'SG';
export const US = 'US';

export function isUS(countryCode: string) {
  return isMatch(US, countryCode);
}

export function isNL(countryCode: string) {
  return isMatch(NL, countryCode);
}

export function isFR(countryCode: string) {
  return isMatch(FR, countryCode);
}

export function isGB(countryCode: string) {
  return isMatch(GB, countryCode);
}

export function isDK(countryCode: string) {
  return isMatch(DK, countryCode);
}

export function isDE(countryCode: string) {
  return isMatch(DE, countryCode);
}

export function isES(countryCode: string) {
  return isMatch(ES, countryCode);
}

export function isBE(countryCode: string) {
  return isMatch(BE, countryCode);
}

export function isBR(countryCode: string) {
  return isMatch(BR, countryCode);
}

export function isCA(countryCode: string) {
  return isMatch(CA, countryCode);
}

export function isMC(countryCode: string) {
  return isMatch(MC, countryCode);
}

export function isIT(countryCode: string) {
  return isMatch(IT, countryCode);
}

export function isJP(countryCode: string) {
  return isMatch(JP, countryCode);
}

export function isSE(countryCode: string) {
  return isMatch(SE, countryCode);
}

export function isAU(countryCode: string) {
  return isMatch(AU, countryCode);
}

export function isCH(countryCode: string) {
  return isMatch(CH, countryCode);
}

export function isIE(countryCode: string) {
  return isMatch(IE, countryCode);
}

export const getCountriesWithMarket = () =>
  globalThis.__VIVINO_GLOBALS__?.COUNTRIES_WITH_MARKET || [];

const getCountriesWithoutCommunityAwardsArticle = () =>
  globalThis.__VIVINO_GLOBALS__?.COUNTRIES_WITHOUT_COMMUNITY_AWARDS_ARTICLE || [];

export const isCountryWithCommunityAwardsArticle = (countryCode: string) =>
  !!countryCode && !getCountriesWithoutCommunityAwardsArticle().includes(countryCode.toLowerCase());

export const isVCCountry = (countryCode: string) =>
  !!countryCode && getCountriesWithMarket().includes(countryCode.toLowerCase());

export const isCountryWithVariableTax = (countryCode: string) => isUS(countryCode);

// Due to legal reasons we want to display Sweden next to a merchant name
export const hideCountryNextToMerchant = (countryCode: string) => isSE(countryCode);

let shipToCountries = null;
export const getShipToCountries = (): Pick<BasicCountry, 'name' | 'code'> => {
  if (!shipToCountries) {
    shipToCountries = Object.entries(globalThis.__VIVINO_GLOBALS__?.COUNTRY_SETTINGS)
      .map(([country_code, country_setting]: [string, { name: string }]) => ({
        code: country_code,
        name: country_setting.name,
      }))
      .sort((item1, item2) => item1.name.localeCompare(item2.name));
  }
  return shipToCountries;
};

export const getDefaultStateForCountry = (countryCode: string) =>
  globalThis.__VIVINO_GLOBALS__?.DEFAULT_STATES_FOR_COUNTRIES?.[
    countryCode?.toLowerCase()
  ]?.toUpperCase();

export const getCountrySetting = (countryCode: string) =>
  globalThis.__VIVINO_GLOBALS__?.COUNTRY_SETTINGS?.[countryCode?.toLowerCase()];

export const isStateBasedShipping = (countryCode: string) =>
  getCountrySetting(countryCode)?.state_shipping_enabled;

export const isStatePathSupported = (countryCode: string) =>
  !!globalThis.__VIVINO_GLOBALS__?.DEFAULT_STATES_FOR_COUNTRIES?.[countryCode?.toLowerCase()];

let shippingStatesForCurrentCountry = null;
export const getShippingStatesForCurrentCountry = (): Pick<CountryState, 'name' | 'code'> => {
  if (!shippingStatesForCurrentCountry) {
    shippingStatesForCurrentCountry = Object.entries(
      globalThis.__VIVINO_GLOBALS__?.STATES_FOR_CURRENT_COUNTRY
    )
      .map(([code, stateData]: [string, { name: string }]) => ({ code, name: stateData.name }))
      .sort((item1, item2) => item1.name.localeCompare(item2.name));
  }
  return shippingStatesForCurrentCountry;
};

export const getStateName = (stateCode: string) =>
  globalThis.__VIVINO_GLOBALS__?.STATES_FOR_CURRENT_COUNTRY?.[stateCode?.toLowerCase()]?.name;

export const getCountryName = (countryCode: string) => getCountrySetting(countryCode)?.name;

export const POSSIBLE_LANGUAGES = {
  da: 'Dansk',
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  nl: 'Nederlands',
  'pt-BR': 'Português (Brasil)',
  'pt-PT': 'Português (Portugal)',
  sv: 'Svenska',
  zh: '中文 ( 香港 )', // TODO: how to display zh-TW etc?
  ja: '日本語',
};

export const isZipBasedShipping = (countryCode: string) =>
  getCountrySetting(countryCode)?.zip_shipping_enabled;

export function getCurrentCountryCode() {
  return globalThis.__PRELOADED_COUNTRY_CODE__ || US;
}

export function getCurrentStateCode() {
  return globalThis.__PRELOADED_STATE_CODE__;
}

export const hasPaymentRequestButton = (countryCode: string) => !isBR(countryCode);

const COUNTRIES_WITH_SECURE_PAYMENTS = [DE, DK, GB, CH, FR, SE, IT, ES, BE, IE, NL];

export const hasSecurePayment = (countryCode: string) =>
  COUNTRIES_WITH_SECURE_PAYMENTS.some((countryCodeWithSecure) =>
    isMatch(countryCodeWithSecure, countryCode)
  );
