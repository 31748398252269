import { useBreakpoint } from '@context/DeviceBreakpointContext/DeviceBreakpointContext';
import { numberOfWinesText, totalRatingsText } from '@helpers/winery';
import { StarFillIcon, Typography, TypographyType } from '@vivino/js-react-common-ui';
import { formatRating, useI18N } from '@vivino/js-web-common';
import { ComponentDirection } from '@webtypes/common';
import { FullWinery } from '@webtypes/goApi';
import cx from 'classnames';
import React from 'react';

import { formatNumber } from '@lib/numbers';

import styles from './wineryStats.module.scss';

interface WineryStatsProps {
  winery: FullWinery;
  locale: string;
  direction?: ComponentDirection;
  showWinesCount?: boolean;
}

const TRANSLATIONS_PATH = 'components.shared.winery_statistics';

const TRANSLATIONS: { [key: string]: string } = {
  number_of_wines_one: `${TRANSLATIONS_PATH}.number_of_wines.one`,
  number_of_wines_other: `${TRANSLATIONS_PATH}.number_of_wines.other`,
  number_of_wines_zero: `${TRANSLATIONS_PATH}.number_of_wines.zero`,
  total_rating_one: `${TRANSLATIONS_PATH}.total_ratings.one`,
  total_ratings_other: `${TRANSLATIONS_PATH}.total_ratings.other`,
  total_ratings_zero: `${TRANSLATIONS_PATH}.total_ratings.zero`,
};

function WineryStats({
  winery,
  locale,
  direction = ComponentDirection.Vertical,
  showWinesCount = true,
}: WineryStatsProps) {
  const { t } = useI18N();
  const { isTablet, isDesktop } = useBreakpoint();

  const fontStatsText =
    isTablet || isDesktop ? TypographyType.BodyMedium : TypographyType.BodySmall;

  return (
    <div
      className={cx(styles.wineryStats, {
        [styles.horizontal]: direction === ComponentDirection.Horizontal,
      })}
    >
      {showWinesCount && (
        <Typography type={fontStatsText} className={styles.winesCount}>
          {winery.statistics.wines_count > 0 && (
            <>
              <strong>{winery.statistics.wines_count}</strong>{' '}
            </>
          )}

          {t(numberOfWinesText(winery.statistics.wines_count, TRANSLATIONS))}
        </Typography>
      )}

      <div className={styles.ratingWrapper}>
        <StarFillIcon />

        <Typography type={fontStatsText}>
          {winery.statistics.ratings_average > 0 && (
            <>
              <strong>{formatRating(winery.statistics.ratings_average)}</strong>{' '}
            </>
          )}

          {t(totalRatingsText(winery.statistics.ratings_count, TRANSLATIONS), {
            count: formatNumber({ amount: winery.statistics.ratings_count, locale }),
          })}
        </Typography>
      </div>
    </div>
  );
}

export default WineryStats;
