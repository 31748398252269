import DeliveryIcon from '@icons/Delivery/48/Delivery';
import QuestionBubble from '@icons/QuestionBubble/48/QuestionBubble';
import RevolvingStars from '@icons/RevolvingStars/48/RevolvingStars';
import RibbonAward from '@icons/RibbonAward/48/RibbonAward';
import { useI18N } from '@vivino/js-web-common';
import React from 'react';

import styles from './vivinoBulletPoints.scss';

const TRANSLATION_PATH = 'components.shared.footer';
const TRANSLATIONS = {
  shopTheWorldsLargest: `${TRANSLATION_PATH}.shop_the_worlds_largest_wine_marketplace`,
  hereToHelp: `${TRANSLATION_PATH}.our_support_team_is_always_here_to_help`,
  carefulDelivery: `${TRANSLATION_PATH}.careful_delivery_right_to_your_doorstep`,
  checkHonest: `${TRANSLATION_PATH}.check_honest_reviews_of_any_wine_before_purchase`,
};

const VIVINO_BULLET_POINTS = [
  {
    icon: <RibbonAward className={styles.vivinoQualityIcon} />,
    textKey: TRANSLATIONS.shopTheWorldsLargest,
  },
  {
    icon: <QuestionBubble className={styles.vivinoQualityIcon} />,
    textKey: TRANSLATIONS.hereToHelp,
  },
  {
    icon: <DeliveryIcon className={styles.vivinoQualityIcon} />,
    textKey: TRANSLATIONS.carefulDelivery,
  },
  {
    icon: <RevolvingStars className={styles.vivinoQualityIcon} />,
    textKey: TRANSLATIONS.checkHonest,
  },
];

const VivinoBulletPoints = () => {
  const { t } = useI18N();
  return (
    <div className={styles.vivinoBulletPoints}>
      {VIVINO_BULLET_POINTS.map(({ icon, textKey }) => (
        <div className={styles.iconWithText} key={textKey}>
          {icon}
          <div className={styles.vivinoQualityText}>{t(textKey)}</div>
        </div>
      ))}
    </div>
  );
};

export default VivinoBulletPoints;
