import { UserRatingStar, UserRatingStarType, imagesUri, useI18N } from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { EXTERNAL, GLASS, HEART, StarIconsMap, USER_STAR_INVERTED } from './images';
import styles from './rating.module.scss';

export const RatingType = {
  Vivino: 'vivino',
  User: 'user',
  UserInverted: 'user_inverted',
  External: 'external',
  Glasses: 'glasses',
  Hearts: 'hearts',
};

const ThemeIconsMap = {
  [RatingType.UserInverted]: USER_STAR_INVERTED,
  [RatingType.External]: EXTERNAL,
  [RatingType.Glasses]: GLASS,
  [RatingType.Hearts]: HEART,
};

const THEMES_WITH_PLACEHOLDER_ICONS = [RatingType.Vivino, RatingType.User];

const TRANSLATIONS_PATH = 'components.shared.rating';

const TRANSLATIONS = {
  rated: `${TRANSLATIONS_PATH}.rated`,
  stars: `${TRANSLATIONS_PATH}.stars`,
  glasses: `${TRANSLATIONS_PATH}.glasses`,
  hearts: `${TRANSLATIONS_PATH}.hearts`,
};

export const RatingSize = {
  SMALL: 14,
  MEDIUM: 16,
  LARGE: 22,
};

function iconMargin(size) {
  if (size < 22) {
    return '0 2px';
  } else {
    return '0 4px';
  }
}

const HALF_RATING = '0.5';

const Rating = ({
  size = 14,
  theme = RatingType.Vivino,
  margin,
  brightness,
  maxRating = 5,
  rating,
  className,
}) => {
  const { t } = useI18N();
  const style = {
    height: `${size}px`,
    width: `${size}px`,
    ...((theme === RatingType.External || theme === RatingType.Hearts) && {
      margin: iconMargin(size),
    }),
    ...(margin && {
      margin,
    }),
    ...(brightness && {
      filter: `brightness(${brightness})`,
    }),
  };
  let elements = [];

  const ratingLength = THEMES_WITH_PLACEHOLDER_ICONS.includes(theme)
    ? maxRating
    : Math.ceil(rating);

  if (theme === RatingType.User) {
    elements = Array.from({ length: ratingLength }, (_, index) => {
      const currentStar = index + 1;
      const starValue = (currentStar - rating).toFixed(1);

      let starType;

      if (starValue > HALF_RATING) {
        starType = UserRatingStarType.EmptyStar;
      } else if (starValue === HALF_RATING) {
        starType = UserRatingStarType.HalfStar;
      } else {
        starType = UserRatingStarType.FullStar;
      }

      return (
        <UserRatingStar
          starType={starType}
          key={`${starType}${index}`}
          style={style}
          className={cx(styles.icon)}
        />
      );
    });
  } else {
    elements = Array.from({ length: ratingLength }, (_, index) => {
      const currentStar = index + 1;
      const absolutePercent = ((rating || 0) - currentStar + 1) * 100;
      const percentBetween0And100 = Math.max(0, Math.min(absolutePercent, 100)).toFixed(0);
      const star =
        theme === RatingType.UserInverted ? RatingType.UserInverted : percentBetween0And100;

      let startImage = ThemeIconsMap[theme];
      if (!startImage) {
        startImage = StarIconsMap[percentBetween0And100];
      }

      const starStyle = { ...style, backgroundImage: `url(${imagesUri(startImage)})` };

      return (
        <i
          style={starStyle}
          key={`${star}${index}`}
          className={cx(styles.icon, styles[`icon${star}`])}
        />
      );
    });
  }

  return (
    <div
      role="img"
      aria-label={t(TRANSLATIONS.rated, {
        rating,
        rating_type: t(TRANSLATIONS[theme] || TRANSLATIONS.stars),
      })}
      className={cx(styles.rating, styles[theme], className)}
    >
      {elements}
    </div>
  );
};

Rating.propTypes = {
  className: PropTypes.string,
  maxRating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  theme: PropTypes.oneOf([
    RatingType.Vivino,
    RatingType.User,
    RatingType.External,
    RatingType.Glasses,
    RatingType.Hearts,
  ]),
  size: PropTypes.number,
  brightness: PropTypes.number,
  margin: PropTypes.string,
};

export default Rating;
