import { Typography, TypographyType } from '@vivino/js-react-common-ui';
import { UserAlias, UserAvatar, UserRating } from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { CommonPropTypes, ComponentSizes } from 'vivino-js/commonPropTypes';
import Card from 'vivino-ui/atoms/Card';

import TruncatedText, { NumTruncateLines } from '@components/TruncatedText/TruncatedText';

import CommentsButton from '../CommentsButton';
import CommentsSection from '../CommentsSection';
import LikeButton from '../LikeButton';
import MoreActionsButton from '../MoreActionsButton';
import ReviewAnchor from '../ReviewAnchor';
import ReviewDate from '../ReviewDate';
import ReviewedVintageYear from '../ReviewedVintageYear';
import styles from './communityReview.scss';

const CommunityReviewPresentational = ({
  onReviewUpdated,
  onReviewDeleted,
  commentsCount,
  onUpdateCommentsCount,
  onCommentClick,
  review,
  isCommentsVisible = false,
  isReviewedVintageYearVisible = false,
  vintageYear,
  isUserActionEnabled = false,
  size = ComponentSizes.Base,
  getReviewNote = null,
  className,
  isEditable = false,
  renderReviewContentAsCard = false,
}) => {
  const { user } = review;
  const activityId = review?.activity?.id;

  // by default, simply displays tagged_note
  // optionally, allows to transform review note by passing this function
  // like in taste characteristics where taste note keywords need to be highlighted with HTML tag
  if (!getReviewNote) {
    getReviewNote = (review) => review.tagged_note;
  }

  const ReviewContent = () => {
    const content = (
      <>
        <UserRating rating={review.rating} className={styles.userRating} />
        {isReviewedVintageYearVisible && (
          <ReviewedVintageYear
            review={review}
            vintageYear={vintageYear}
            className={styles.vintageText}
          />
        )}
        <Typography
          type={TypographyType.BodyExtraLarge}
          dangerouslySetInnerHTML={{ __html: getReviewNote(review) }}
        />
      </>
    );

    if (size === ComponentSizes.Large) {
      return content;
    }

    return (
      <TruncatedText numLines={NumTruncateLines.Four} className={styles.truncatedText}>
        {content}
      </TruncatedText>
    );
  };

  const renderReviewContent = (children) =>
    renderReviewContentAsCard ? <Card className={styles.card}>{children}</Card> : children;

  return (
    <div
      className={cx({
        [styles.large]: size === ComponentSizes.Large,
      })}
      data-testid="communityReview"
    >
      <div className={className}>
        {renderReviewContent(
          <ReviewAnchor review={review} className={styles.reviewContent}>
            <ReviewContent />
          </ReviewAnchor>
        )}
        <div className={styles.reviewInfo}>
          <div className={styles.avatarAndText}>
            <UserAvatar user={user} className={styles.avatar} />
            <div className={styles.textInfo}>
              <UserAlias user={user} size={size} withRatingsCount className={styles.userAlias} />
              <ReviewDate review={review} size={size} />
            </div>
          </div>
          {isUserActionEnabled && (
            <div className={styles.userActions}>
              {activityId && (
                <>
                  <LikeButton activity={review.activity} className={styles.innerUserAction} />
                  <CommentsButton
                    commentsCount={commentsCount}
                    onClick={onCommentClick}
                    className={styles.innerUserAction}
                  />
                </>
              )}
              {isEditable && (
                <MoreActionsButton
                  review={review}
                  onReviewUpdated={onReviewUpdated}
                  onReviewDeleted={onReviewDeleted}
                  className={styles.innerUserAction}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <CommentsSection
        activityId={activityId}
        isCommentsVisible={isCommentsVisible}
        commentsCount={commentsCount}
        onUpdateCommentsCount={onUpdateCommentsCount}
        size={size}
      />
    </div>
  );
};

CommunityReviewPresentational.propTypes = {
  review: PropTypes.object.isRequired,
  size: CommonPropTypes.componentSize,
  className: PropTypes.string,
  getReviewNote: PropTypes.func,
  isEditable: PropTypes.bool,

  /**
   * The vintage year on the wine page
   */
  isReviewedVintageYearVisible: PropTypes.bool,
  vintageYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  onReviewUpdated: PropTypes.func,
  onReviewDeleted: PropTypes.func,

  // below props are required only if isUserActionEnabled=true
  isUserActionEnabled: PropTypes.bool,
  commentsCount: PropTypes.number,
  isCommentsVisible: PropTypes.bool,
  onCommentClick: PropTypes.func,
  onUpdateCommentsCount: PropTypes.func,
  renderReviewContentAsCard: PropTypes.bool,
};

export default CommunityReviewPresentational;
