const BASE_URL = 'rating';

const EXTERNAL = `${BASE_URL}/external_review_star_10.svg`;
const GLASS = `${BASE_URL}/glass_10.svg`;
const HEART = `${BASE_URL}/heart_10.svg`;
const STAR0 = `${BASE_URL}/star_0.svg`;
const STAR1 = `${BASE_URL}/star_1.svg`;
const STAR10 = `${BASE_URL}/star_10.svg`;
const STAR2 = `${BASE_URL}/star_2.svg`;
const STAR3 = `${BASE_URL}/star_3.svg`;
const STAR4 = `${BASE_URL}/star_4.svg`;
const STAR5 = `${BASE_URL}/star_5.svg`;
const STAR6 = `${BASE_URL}/star_6.svg`;
const STAR7 = `${BASE_URL}/star_7.svg`;
const STAR8 = `${BASE_URL}/star_8.svg`;
const STAR9 = `${BASE_URL}/star_9.svg`;
const USER_STAR_INVERTED = `${BASE_URL}/star_user_inverted.svg`;

const StarIconsMap = {
  0: STAR0,
  10: STAR1,
  20: STAR2,
  30: STAR3,
  40: STAR4,
  50: STAR5,
  60: STAR6,
  70: STAR7,
  80: STAR8,
  90: STAR9,
  100: STAR10,
};

export { EXTERNAL, GLASS, HEART, USER_STAR_INVERTED, StarIconsMap };
