import { useEffect, useState } from 'react';
import { isSSR } from '@helpers/ssr';

interface UpdateSearchParamsOptions {
  replace?: boolean;
}

interface UseSearchParamsResult {
  searchParams: URLSearchParams;
  updateSearchParams: (
    callback: (previousParams: URLSearchParams) => URLSearchParams,
    options?: UpdateSearchParamsOptions
  ) => void;
}

const useSearchParams = (initialParams?: URLSearchParams): UseSearchParamsResult => {
  const initialState = isSSR ? initialParams : new URLSearchParams(window.location.search);
  const [searchParams, setSearchParams] = useState(initialState);

  useEffect(() => {
    if (isSSR) return;

    setSearchParams(new URLSearchParams(window.location.search));

    const handlePopstate = () => {
      setSearchParams(new URLSearchParams(window.location.search));
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [isSSR]);

  const updateSearchParams = (
    callback: (previousParams: URLSearchParams) => URLSearchParams,
    options: UpdateSearchParamsOptions = {}
  ): void => {
    if (isSSR || !searchParams) return;

    const previousParams = new URLSearchParams(searchParams);
    const updatedParams = callback(previousParams);

    const newUrl = `${window.location.pathname}?${updatedParams.toString()}`;

    if (options.replace) {
      window.history.replaceState({}, '', newUrl);
    } else {
      window.history.pushState({}, '', newUrl);
    }

    setSearchParams(updatedParams);
  };

  return { searchParams, updateSearchParams };
};

export default useSearchParams;
