import { isAU, isFR } from '@helpers/countryHelpers';
import { useBreakpoint } from '@vivino/js-react-common-ui';
import {
  LayoutMargin,
  VivinoIcon,
  i18nRootUrl,
  sortSiteMapBySortId,
  useI18N,
} from '@vivino/js-web-common';
import PropTypes from 'prop-types';
import React from 'react';

import Copyright from './Copyright';
import DownloadAppButtons from './DownloadAppButtons';
import PaymentOptionIcons from './PaymentOptionIcons';
import SiteMap from './SiteMap';
import SocialMediaLinks from './SocialMediaLinks';
import VivinoBulletPoints from './VivinoBulletPoints';
import styles from './footer.scss';

const TRANSLATION_PATH = 'components.shared.footer';
const TRANSLATION_PATH_GIFTING = 'components.navigation.shared.navigation_gifting';

const TRANSLATIONS = {
  legal: `${TRANSLATION_PATH}.legal`,
  trustedByMillions: `${TRANSLATION_PATH}.trusted_by_millions_to_discover_and_buy_the_right_wine_every_time`,
  linkTitleApp: `${TRANSLATION_PATH}.link_title.app`,
  linkTitleWineNews: `${TRANSLATION_PATH}.link_title.wine_news`,
  linkTitleWineAwards: `${TRANSLATION_PATH}.link_title.wine_awards`,
  linkTitleAbout: `${TRANSLATION_PATH}.link_title.about`,
  linkTitleContact: `${TRANSLATION_PATH}.link_title.contact`,
  linkTitlePress: `${TRANSLATION_PATH}.link_title.press`,
  linkTitleSupport: `${TRANSLATION_PATH}.link_title.support`,
  linkTitleTermsOfUse: `${TRANSLATION_PATH}.link_title.terms_of_use`,
  linkTitleTermsOfSale: `${TRANSLATION_PATH}.link_title.terms_of_sale`,
  linkTitlePrivacyPolicy: `${TRANSLATION_PATH}.link_title.privacy_policy`,
  linkTitleContentPolicy: `${TRANSLATION_PATH}.link_title.content_policy`,
  linkTitleRankingPolicy: `${TRANSLATION_PATH}.link_title.ranking_policy`,
  linkTitleSitemap: `${TRANSLATION_PATH}.link_title.sitemap`,
  linkTitleToplists: `${TRANSLATION_PATH}.link_title.toplists`,
  linkTitleMerchants: `${TRANSLATION_PATH}.link_title.merchants`,
  linkTitleBrandPartnerships: `${TRANSLATION_PATH}.link_title.brand_partnerships`,
  linkTitleAffiliate: `${TRANSLATION_PATH}.link_title.affiliate`,
  linkTitleCareers: `${TRANSLATION_PATH}.link_title.careers`,
  linkTitleGifting: `${TRANSLATION_PATH_GIFTING}.gifting`,
};

export const getMapping = ({ countryCode = '', stateCode = null, locale = '', isVCCountry }) => [
  {
    link: '/app',
    titleKey: TRANSLATIONS.linkTitleApp,
    sortId: 1,
  },
  {
    link: '/about',
    titleKey: TRANSLATIONS.linkTitleAbout,
    sortId: 2,
  },
  {
    link: `${i18nRootUrl({ countryCode, stateCode, locale, vCCountry: isVCCountry })}premium`,
    titleKey: 'Premium',
    sortId: 2.1,
  },
  {
    link: `${i18nRootUrl({
      countryCode,
      stateCode,
      locale,
      vCCountry: isVCCountry,
    })}premium/gift`,

    titleKey: TRANSLATIONS.linkTitleGifting,
    sortId: 2.2,
  },
  {
    link: '/contact',
    titleKey: TRANSLATIONS.linkTitleContact,
    sortId: 3,
  },
  {
    link: '/press',
    titleKey: TRANSLATIONS.linkTitlePress,
    sortId: 4,
  },
  {
    link: '/wine-news',
    titleKey: TRANSLATIONS.linkTitleWineNews,
    sortId: 6,
  },
  {
    link: '/merchants/signup',
    titleKey: TRANSLATIONS.linkTitleMerchants,
    sortId: 7,
  },
  {
    link: '/partners',
    titleKey: TRANSLATIONS.linkTitleBrandPartnerships,
    sortId: 7.5,
  },
  {
    link: 'https://careers.vivino.com',
    titleKey: TRANSLATIONS.linkTitleCareers,
    sortId: 8,
  },
  {
    link: '/terms',
    titleKey: TRANSLATIONS.linkTitleTermsOfUse,
    sortId: 9,
  },
  {
    link: '/privacy',
    titleKey: TRANSLATIONS.linkTitlePrivacyPolicy,
    sortId: 10,
  },
  {
    link: '/content',
    titleKey: TRANSLATIONS.linkTitleContentPolicy,
    sortId: 11,
  },
  {
    link: '/sitemap',
    titleKey: TRANSLATIONS.linkTitleSitemap,
    sortId: 14,
  },
  {
    link: '/toplists',
    titleKey: TRANSLATIONS.linkTitleToplists,
    sortId: 14.5,
  },
  {
    link: 'https://yourvoice.hibob.com/consult/AVvRzI6lT2',
    titleKey: 'Whistleblower', //TODO: add translation @dennis
    sortId: 15,
  },
];

export const CHECKOUT_SITE_MAP_FR = [
  {
    link: '/ranking',
    titleKey: TRANSLATIONS.linkTitleRankingPolicy,
    sortId: 14,
  },
];

const getSiteMap = ({ countryCode, isVCCountry, stateCode = null, locale }) => {
  let siteMap = getMapping({ countryCode, stateCode, locale, isVCCountry });
  const checkoutSiteMapOffersOrMarketPlace = [
    {
      link: `${i18nRootUrl({
        countryCode,
        stateCode,
        locale,
        vCCountry: isVCCountry,
      })}terms-of-sale`,
      titleKey: TRANSLATIONS.linkTitleTermsOfSale,
      sortId: 13,
    },
  ];

  if (isVCCountry) {
    siteMap = [...siteMap, ...checkoutSiteMapOffersOrMarketPlace];
  }

  if (isFR(countryCode)) {
    siteMap = [...siteMap, ...CHECKOUT_SITE_MAP_FR];
  }

  return siteMap.sort(sortSiteMapBySortId);
};

const getLegalNotice = (countryCode) => {
  if (isFR(countryCode)) {
    return (
      <div className={styles.legalNoticeFR}>
        {'L’abus d’alcool est dangereux pour la santé. À consommer avec modération.'}
      </div>
    );
  } else if (isAU(countryCode)) {
    return (
      <div className={styles.legalNoticeAU}>
        {'Under the Liquor Control Reform Act 1998 it is an offence:'}
        <li>
          {'to supply alcohol to a person under the age of 18 years (penalty exceeds $8,000).'}
        </li>
        <li>
          {
            'for a person under the age of 18 years to purchase or receive liquor (penalty exceeds $700).'
          }
        </li>
        {'Liquor Licence No. 57711084'}
      </div>
    );
  }
};

const Footer = ({ countryCode, stateCode = '', locale = 'en', isVCCountry }) => {
  const { t } = useI18N();
  const { isMobile, isTablet, isDesktop } = useBreakpoint();
  const legalNotice = getLegalNotice(countryCode);

  const socialMediaAndCopyright = (
    <div className={styles.socialAndCopyright}>
      <SocialMediaLinks countryCode={countryCode} />
      <Copyright />
    </div>
  );

  return (
    <footer className={styles.footer} role="contentinfo">
      <LayoutMargin className={styles.topSection}>
        <div className={styles.header}>{t(TRANSLATIONS.trustedByMillions)}</div>
        <VivinoBulletPoints />
        {isMobile && (
          <>
            <PaymentOptionIcons countryCode={countryCode} />
            {legalNotice}
          </>
        )}
      </LayoutMargin>
      <section className={styles.darkSection}>
        <LayoutMargin className={styles.siteMapSection}>
          <div className={styles.siteMapAndSocial}>
            {isDesktop && socialMediaAndCopyright}
            <div className={styles.vivinoIconContainer} data-testid="site-map-links">
              <VivinoIcon className={styles.siteMapIcon} />
              <SiteMap siteMapLinks={getSiteMap({ countryCode, stateCode, locale, isVCCountry })} />
            </div>
          </div>
          {isDesktop && legalNotice}
          <div className={styles.paymentAndDownload}>
            {(isTablet || isDesktop) && (
              <>
                <PaymentOptionIcons countryCode={countryCode} />
                {isTablet && legalNotice}
              </>
            )}
            {<DownloadAppButtons />}
            {!isDesktop && socialMediaAndCopyright}
          </div>
        </LayoutMargin>
      </section>
    </footer>
  );
};

Footer.propTypes = {
  countryCode: PropTypes.string,
  locale: PropTypes.string,
  stateCode: PropTypes.string,
  isCheckout: PropTypes.bool,
  isVCCountry: PropTypes.bool,
};

export default Footer;
