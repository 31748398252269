import { Alert, AlertTheme, Typography, TypographyType } from '@vivino/js-react-common-ui';
import { useAppContext } from '@vivino/js-web-common';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { price } from 'vivino-js/apiPropTypes';
import { isPPCPrice, priceToString } from 'vivino-js/helpers/price';
import t from 'vivino-js/translationString';
import Price from 'vivino-ui/atoms/Price';

import AddToCartButton from 'vivino-ui/components/AddToCartButton';

import styles from './addToCart.scss';

const TRANSLATIONS_PATH = 'components.shared.add_to_cart_button';
const TRANSLATIONS = {
  soldOut: `${TRANSLATIONS_PATH}.sold_out`,
  availableOnline: `${TRANSLATIONS_PATH}.available_online`,
  priceForTheYear: `${TRANSLATIONS_PATH}.price_for_the_year`,
  averagePrice: `${TRANSLATIONS_PATH}.average_price`,
};

const AddToCart = ({
  price,
  seenVintageId,
  vintageYear,
  showOutOfStock = true,
  className,
  fullWidth = false,
}) => {
  const [warningMessage, setWarningMessage] = useState('');

  const handleError = (message) => {
    setWarningMessage(message);
  };

  const { __PRELOADED_TRACKING_SCREEN_NAME__: page } = useAppContext();

  if (!price) {
    if (page === 'Search Page') {
      return null;
    }
    return showOutOfStock ? (
      <div className={cx(styles.subText, styles.soldOut)}>{t(TRANSLATIONS.soldOut)}</div>
    ) : null;
  }

  if (isPPCPrice(price)) {
    if (page === 'Search Page') {
      return null;
    }
    return (
      <div className={cx(styles.subText, styles.ppcPrice)}>
        <Typography component="p" className={styles.price} type={TypographyType.HeadlineSmall}>
          {priceToString({
            amount: price.amount,
            currency: price.currency,
          })}
        </Typography>
        <Typography component="p" className={styles.averagePrice} type={TypographyType.BodyMedium}>
          {t(TRANSLATIONS.averagePrice)}
        </Typography>
      </div>
    );
  }

  return (
    <>
      <AddToCartButton
        price={price}
        seenVintageId={seenVintageId}
        onError={handleError}
        disabled={!!warningMessage}
        className={cx(className, { [styles.isSearchAddToCart]: page === 'Search Page' })}
        fullWidth={fullWidth}
      />
      {warningMessage && (
        <Alert subdued theme={AlertTheme.Critical} className={styles.warningMessage}>
          {warningMessage}
        </Alert>
      )}
      {!!price.discounted_from && (
        <Price
          strike
          amount={price.discounted_from}
          currency={price.currency}
          className={styles.subText}
        />
      )}
      {price.message && <div className={styles.subText}>{price.message}</div>}
      {vintageYear && (
        <div className={styles.subText}>
          {t(TRANSLATIONS.priceForTheYear, { year: vintageYear })}
        </div>
      )}
    </>
  );
};

AddToCart.propTypes = {
  price: price,
  seenVintageId: PropTypes.number.isRequired,
  vintageYear: PropTypes.number,
  showOutOfStock: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
};

export default AddToCart;
