import { CommonPreloadedState } from '@webtypes/preload';
import React, { createContext, useContext, useState } from 'react';

interface PreloadContextProviderProps {
  children: React.ReactNode;
  preloadedState: CommonPreloadedState | null;
}

export interface PreloadContextType {
  preloadedState: CommonPreloadedState | null;
  hasPreloadedState: boolean;
}

export const PreloadContext = createContext<PreloadContextType>(null);

export const PreloadContextProvider = ({
  children,
  preloadedState,
}: PreloadContextProviderProps) => {
  const [preloaded] = useState(preloadedState || null);

  const value: PreloadContextType = {
    preloadedState: preloaded,
    hasPreloadedState: !!preloaded,
  };
  return <PreloadContext.Provider value={value}>{children}</PreloadContext.Provider>;
};

export const usePreloadedState = () => {
  const context = useContext(PreloadContext);
  if (context === undefined) {
    throw new Error('usePreloadedState must be used within a PreloadContextProvider');
  }
  return context;
};
