export const getPreloadedState = <T>(element: HTMLElement): T | null => {
  if (!element) {
    return null;
  }
  if (!element.dataset || !element.dataset['preloadedState']) {
    return null;
  }
  return JSON.parse(element.dataset['preloadedState']) as T;
};

export const getElementAndPreloadedState = <T>(elementID: string): [HTMLElement, T | null] => {
  const element = document.getElementById(elementID);
  return [element, getPreloadedState<T>(element)];
};
