import { DeviceBreakpointProvider } from '@context/DeviceBreakpointContext/DeviceBreakpointContext';
import { PreloadContextProvider } from '@context/PreloadContext/PreloadContext';
import { muiCache } from '@utils/ssr/emotionCache';
import { ThemeProvider } from '@vivino/js-react-common-ui';
import {
  AppContextProps,
  AppProvider,
  ExperimentAttributesProvider,
  I18NProvider,
} from '@vivino/js-web-common';
import { DefaultPageProps } from '@webtypes/common';
import { CommonPreloadedState } from '@webtypes/preload';
import React, { ReactNode } from 'react';
import { ErrorBoundary } from 'vivino-js/utilities/ErrorBoundary';

import SnackBarProvider from '@components/SnackBarProvider/SnackBarProvider';

interface PageProps extends DefaultPageProps {
  children: ReactNode;
  preloadedState?: CommonPreloadedState;
  latest?: boolean;
}

const windowToAppContext = ({ countryCode, stateCode }): AppContextProps => {
  return {
    __PRELOADED_ENV__: globalThis.__PRELOADED_ENV__,
    __PRELOADED_COUNTRY_CODE__: countryCode || globalThis.__PRELOADED_COUNTRY_CODE__,
    __PRELOADED_STATE_CODE__: stateCode || globalThis.__PRELOADED_STATE_CODE__,
    __PRELOADED_LOCALE__: globalThis.__PRELOADED_LOCALE__,
    __PRELOADED_SIGNED_IN_USER__: globalThis.__PRELOADED_SIGNED_IN_USER__,
    __PRELOADED_STATE__: globalThis.__PRELOADED_STATE__,
    __PRELOADED_SPLIT_CLIENT_KEY__: globalThis.__PRELOADED_SPLIT_CLIENT_KEY__,
    __PRELOADED_USER_ACCESSIBILITY_ENABLED__: globalThis.__PRELOADED_USER_ACCESSIBILITY_ENABLED__,
    __PRELOADED_CAMPAIGN_USER_ID__: globalThis.__PRELOADED_CAMPAIGN_USER_ID__,
    __PRELOADED_ANONYMOUS_TRACKING_ID__: globalThis.__PRELOADED_ANONYMOUS_TRACKING_ID__,
    __PRELOADED_AB_TESTS__: globalThis.__PRELOADED_AB_TESTS__,
    __PRELOADED_SMOKE_TEST__: globalThis.__PRELOADED_SMOKE_TEST__,
    __PRELOADED_TRACKING_SCREEN_NAME__: globalThis.__PRELOADED_TRACKING_SCREEN_NAME__,
    __PRELOADED_IS_BOT_REQUEST__: globalThis.__PRELOADED_IS_BOT_REQUEST__,
    __VIVINO_GLOBALS__: globalThis.__VIVINO_GLOBALS__,
    __PRELOADED_USER_NOTIFICATION__: globalThis.__PRELOADED_USER_NOTIFICATION__,
    __PRELOADED_SHIPPING_ZIP_CODE__: globalThis.__PRELOADED_SHIPPING_ZIP_CODE__,
    __PRELOADED_SHIPPING_ZIP_PREFILL__: globalThis.__PRELOADED_SHIPPING_ZIP_PREFILL__,
    __WEB_API_HOST__: globalThis.__WEB_API_HOST__,
    vivinoCacheKey: globalThis.vivinoCacheKey,
    locale: globalThis.locale,
    menuData: globalThis.menuData,
    userCellars: globalThis.__PRELOADED_USER_CELLARS__,
  };
};

const preloadedStateToAppContext = (preloadedState: CommonPreloadedState): AppContextProps => {
  return {
    // Server
    __PRELOADED_ENV__: preloadedState.environment,
    __PRELOADED_IS_BOT_REQUEST__: preloadedState.is_bot,
    // Page Settings
    __PRELOADED_COUNTRY_CODE__: preloadedState.page_settings.ship_to.country_code,
    __PRELOADED_STATE_CODE__: preloadedState.page_settings.ship_to.state_code,
    __PRELOADED_SHIPPING_ZIP_CODE__: preloadedState.page_settings.ship_to.zip,
    __PRELOADED_LOCALE__: preloadedState.page_settings.locale,
    locale: preloadedState.page_settings.locale,
    __PRELOADED_USER_ACCESSIBILITY_ENABLED__: preloadedState.page_settings.accessibility_enabled,
    __PRELOADED_AB_TESTS__: preloadedState.page_settings.ab_testing,
    // Tracking
    __PRELOADED_TRACKING_SCREEN_NAME__: preloadedState.tracking.screen_name,
    __PRELOADED_CAMPAIGN_USER_ID__: preloadedState.tracking.campaign_user_id,
    __PRELOADED_ANONYMOUS_TRACKING_ID__: preloadedState.tracking.anonymous_tracking_id,
    // Third Party
    __PRELOADED_SPLIT_CLIENT_KEY__: preloadedState.third_party.split_api_key,
    // Legacy. We use the following from the window, since some things rely on it.
    vivinoCacheKey: globalThis.vivinoCacheKey,
    menuData: globalThis.menuData,
    userCellars: globalThis.__PRELOADED_USER_CELLARS__,
    __PRELOADED_SIGNED_IN_USER__: globalThis.__PRELOADED_SIGNED_IN_USER__,
    __PRELOADED_SHIPPING_ZIP_PREFILL__: globalThis.__PRELOADED_SHIPPING_ZIP_PREFILL__,
    __PRELOADED_STATE__: globalThis.__PRELOADED_STATE__,
    __PRELOADED_SMOKE_TEST__: globalThis.__PRELOADED_SMOKE_TEST__,
    __VIVINO_GLOBALS__: globalThis.__VIVINO_GLOBALS__,
    __WEB_API_HOST__: globalThis.__WEB_API_HOST__,
    __PRELOADED_USER_NOTIFICATION__: globalThis.__PRELOADED_USER_NOTIFICATION__,
  };
};

/*
 * The Page component can be used for adding providers
 * that needs to be available for all components.
 * For instance it adds the ThemeProvider, which allows us to define the theme for Common UI, which
 * is necessary to get the correct look of components consumed through the Common UI.
 * It should be the top most component of any ReactDOM.render.
 */

const Page = ({
  children,
  countryCode,
  stateCode,
  isMobile = false,
  isTablet = false,
  ...props
}: PageProps) => {
  const hasPreloadedState = !!props?.preloadedState;
  const appContextProps = hasPreloadedState
    ? preloadedStateToAppContext(props.preloadedState)
    : windowToAppContext({ countryCode, stateCode });
  const translations = hasPreloadedState
    ? props.preloadedState.page_settings.translations
    : props?.translations || globalThis.localePack;
  return (
    <ErrorBoundary>
      <PreloadContextProvider preloadedState={props?.preloadedState}>
        <AppProvider value={appContextProps}>
          <I18NProvider localePack={translations}>
            <ThemeProvider cache={muiCache}>
              <SnackBarProvider>
                <ExperimentAttributesProvider>
                  <DeviceBreakpointProvider isMobile={isMobile} isTablet={isTablet}>
                    {children}
                  </DeviceBreakpointProvider>
                </ExperimentAttributesProvider>
              </SnackBarProvider>
            </ThemeProvider>
          </I18NProvider>
        </AppProvider>
      </PreloadContextProvider>
    </ErrorBoundary>
  );
};

export default Page;
