import { hasPaymentRequestButton, isBE, isBR, isNL } from '@helpers/countryHelpers';
import {
  AmericanExpressIcon,
  ApplePayIcon,
  BancontactIcon,
  DiscoverIcon,
  GooglePayIcon,
  IdealIcon,
  MasterCardIcon,
  PayPalIcon,
  VisaIcon,
  useI18N,
} from '@vivino/js-web-common';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './paymentOptionIcons.scss';

const TRANSLATION_PATH = 'components.shared.footer';

const TRANSLATIONS = {
  paymentOptionsWillVary: `${TRANSLATION_PATH}.payment_options_will_vary_by_merchant`,
};

const PaymentOptionIcons = ({ countryCode }) => {
  const { t } = useI18N();
  return (
    <div>
      <div className={styles.allPaymentOptions}>
        <div className={styles.paymentOptions}>
          <VisaIcon className={styles.paymentIcon} dataTestId="visaIcon" />
          {!isBR(countryCode) && (
            <AmericanExpressIcon className={styles.paymentIcon} dataTestId="amexIcon" />
          )}
          <MasterCardIcon className={styles.paymentIcon} dataTestId="masterCardIcon" />
          {!isBR(countryCode) && (
            <DiscoverIcon className={styles.paymentIcon} dataTestId="discoverIcon" />
          )}
        </div>
        <div className={styles.paymentOptions}>
          {hasPaymentRequestButton(countryCode) && (
            <>
              <ApplePayIcon className={styles.paymentIcon} dataTestId="applePayIcon" />
              <GooglePayIcon className={styles.paymentIcon} dataTestId="googlePayIcon" />
            </>
          )}
          <PayPalIcon className={styles.paymentIcon} dataTestId="payPalIcon" />
          {isNL(countryCode) && <IdealIcon className={styles.paymentIcon} dataTestId="iDealIcon" />}
          {isBE(countryCode) && (
            <BancontactIcon className={styles.paymentIcon} dataTestId="bancontactIcon" />
          )}
        </div>
      </div>
      <div className={styles.paymentDisclaimer}>{t(TRANSLATIONS.paymentOptionsWillVary)}</div>
    </div>
  );
};

PaymentOptionIcons.propTypes = {
  countryCode: PropTypes.string,
};

export default PaymentOptionIcons;
