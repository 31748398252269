import { useI18N } from '@vivino/js-web-common';
import React from 'react';

import styles from './copyright.scss';

const TRANSLATION_PATH = 'components.shared.footer';
const TRANSLATIONS = {
  legal: `${TRANSLATION_PATH}.legal`,
};

const Copyright = () => {
  const { t } = useI18N();
  return (
    <div className={styles.copyright}>
      {t(TRANSLATIONS.legal, {
        year: new Date().getFullYear(),
      })}
    </div>
  );
};

export default Copyright;
