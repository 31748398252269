import React from 'react';

interface RevolvingStarsProps {
  className?: string;
}

const RevolvingStars = ({ className }: RevolvingStarsProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    className={className}
  >
    <g id="f7d45d16-a6a5-480a-a631-41c858fdc501" data-name="48">
      <g id="a0439902-721a-471b-ba52-c5cf42ed2609" data-name="ratings">
        <g id="e9fd6674-e1bb-4bc5-9e77-ebcb2a0a6b54" data-name="stars">
          <path
            d="M45.1513,20.56a.9491.9491,0,0,0,.2225-.9446.8768.8768,0,0,0-.7028-.627l-6.9586-1.0612a.7622.7622,0,0,1-.5762-.4408l-3.11-6.6059a.8774.8774,0,0,0-1.5562.0006l-3.11,6.6107a.7706.7706,0,0,1-.5751.4354l-6.9586,1.0612a.8772.8772,0,0,0-.7028.6258.9477.9477,0,0,0,.2225.9446l5.035,5.1455a.8177.8177,0,0,1,.2145.7067L25.41,33.6686a.9354.9354,0,0,0,.3446.8994.8209.8209,0,0,0,.9127.0738l6.2239-3.4312a.7343.7343,0,0,1,.721.0036L39.83,34.6406a.8287.8287,0,0,0,.915-.0726.9393.9393,0,0,0,.3434-.8994L39.9,26.4018a.8068.8068,0,0,1,.2122-.6912Z"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g id="acb93f66-3a8e-4585-aff1-3fef548af26f" data-name="rosé">
          <path
            d="M17.8535,27.8551c.1549-.1293,3.6046-2.5295,5.9923-4.6422"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.1955,17.933a15.5984,15.5984,0,0,0-.0438-5.8817c-.5925-3.4-1.9545-9.57-1.9545-9.57H10.5113s-1.3619,6.1691-1.9544,9.57c-.7016,4.0291-.1833,7.1834,1.54,9.3807,1.74,2.2174,7.5555,6.2543,7.7562,6.4231"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="17.8539"
            y1="27.8551"
            x2="17.8539"
            y2="43.6809"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="9.4539"
            y1="43.6809"
            x2="26.2539"
            y2="43.6809"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="8.5565"
            y1="14.3983"
            x2="27.1513"
            y2="14.3983"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="9.0382"
            y1="19.2817"
            x2="11.361"
            y2="16.8924"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="10.7787"
            y1="21.6776"
            x2="15.3827"
            y2="17.1654"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="12.7432"
            y1="23.9673"
            x2="18.6685"
            y2="18.1602"
            fill="none"
            stroke="#1e1e1e"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default RevolvingStars;
