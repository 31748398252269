import { WineryForm, getLocalCacheKey, generateQueryUrl } from '@vivino/js-web-common';
import { BasicWinery, FullWinery } from '@webtypes/goApi';

import { apiGet, apiPut } from './apiClient';

interface UpdateWineryProps {
  wineryId: number;
  winery: WineryForm;
}

interface WineriesResponse {
  records: number;
  matches: FullWinery[];
}

export const updateWinery = ({ wineryId, winery }: UpdateWineryProps): Promise<FullWinery> => {
  const url = `/api/wineries/${wineryId}`;

  return apiPut({
    url,
    body: {
      winery,
    },
  });
};

export function fetchSponsoredWineries(): Promise<{ wineries: BasicWinery[] }> {
  return apiGet({
    url: `/api/sponsored_wineries?cache_key=${globalThis.vivinoCacheKey}${getLocalCacheKey()}`,
  });
}

export const fetchWineries = async ({
  page,
  search,
  countryCodes,
  regionIds,
  rating,
  signal,
}: {
  page?: number;
  search?: string;
  countryCodes?: string[];
  regionIds?: number[];
  rating?: number;
  signal?: AbortSignal;
}): Promise<WineriesResponse> => {
  const params = [];

  if (page) {
    params.push({ name: 'page', value: page });
  }

  if (search) {
    params.push({ name: 'search', value: search });
  }

  if (countryCodes) {
    params.push({ name: 'country_codes', value: countryCodes });
  }

  if (regionIds) {
    params.push({ name: 'region_ids', value: regionIds });
  }

  if (rating) {
    params.push({ name: 'rating', value: rating });
  }

  const url = generateQueryUrl({ baseUrl: `/api/wineries`, params });
  const options = signal ? { signal } : undefined;

  return apiGet({ url, options });
};
