import { RubyLibMerchantCheckoutPricesAndAvailability } from '@webtypes/rubyLibApi';
import { apiGet } from 'vivino-js/apiClient';
import generateQueryUrl from 'vivino-js/generateQueryUrl';

export function fetchCheckoutPrices({
  vintageId,
}): Promise<{ prices: RubyLibMerchantCheckoutPricesAndAvailability }> {
  const url = generateQueryUrl({
    baseUrl: '/api/checkout_prices',
    params: [{ name: 'vintage_id', value: vintageId }],
  });
  return apiGet({ url });
}

export function fetchWineCheckoutPrices({
  wineId,
}): Promise<{ checkout_prices: RubyLibMerchantCheckoutPricesAndAvailability }> {
  const url = `/api/wines/${wineId}/checkout_prices`;

  return apiGet({ url });
}
