import { useI18N } from '@vivino/js-web-common';
import React from 'react';

import styles from './siteMap.scss';

interface SiteMapLinkProps {
  link: string;
  titleKey: string;
  target?: string;
}

interface SiteMapProps {
  siteMapLinks: SiteMapLinkProps[];
}

const SiteMap = ({ siteMapLinks }: SiteMapProps) => {
  const { t } = useI18N();
  return (
    <div className={styles.siteMap}>
      {siteMapLinks.map((element) => {
        const title = t(element.titleKey) as string;
        return (
          <a
            className={styles.siteMapLink}
            key={element.link}
            href={element.link}
            title={title}
            target={element.target}
          >
            {title}
          </a>
        );
      })}
    </div>
  );
};

export default SiteMap;
